import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import evaluteVisibilityFromDependencies from "utils/evaluteVisibilityFromDependencies";

import HiddenFieldProps from "./HiddenFieldProps";

const HiddenField = ({
    id,
    identifier,
    predefinedValue,
    elementName,
    className,
    control,
    attributes,
    setValue,
    dependencies,
}: HiddenFieldProps): ReactElement => {
    const formContext = useFormContext();
    const defaultvalue = predefinedValue || attributes?.value || "";
    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateHidden = (event: any) => {
        if (
            event.detail?.value &&
            (event.detail.id === id || event.detail.id === identifier)
        ) {
            setValue &&
                elementName &&
                setValue(elementName, event.detail.value);
        }
    };

    React.useEffect(() => {
        window.addEventListener("updateHidden", updateHidden);

        // cleanup listeners
        return () => {
            window.removeEventListener("updateHidden", updateHidden);
        };
    });

    useEffect(() => {
        if (elementName && setValue)
            if (visibleFromDependencies) setValue(elementName, defaultvalue);
            else formContext.unregister(elementName);
    }, [visibleFromDependencies]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {visibleFromDependencies && (
                <Controller
                    name={elementName || ""}
                    control={control}
                    defaultValue={defaultvalue}
                    render={({ field }) => (
                        <input
                            id={id || identifier}
                            className={className}
                            type="hidden"
                            {...field}
                            {...attributes}
                        />
                    )}
                />
            )}
        </>
    );
};

export default React.memo(HiddenField);
